import React, { Component } from 'react';
import { AlertError } from '../Alert/Alert';
import axios from 'axios';
import { APIURL } from '../../config/config';

export default class SalesContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_number: '',
      car_sell_pay_list: [],
      carSell: '',
      sum_car_book: '-',
      guarantor_name: '',
      guarantor_address: ''
    }
    this.onSubmit=this.onSubmit.bind(this)
    this.onInputChange=this.onInputChange.bind(this)
    this.loadDataCarSellPay=this.loadDataCarSellPay.bind(this)
  }

  componentDidMount() {
    let carSell = this.props.carSell
    let car_id = carSell.car_id
    let customer_id = carSell.customer_id
    let contract_number = carSell.contract_number
    let guarantor_name = carSell.guarantor_name
    let guarantor_address = carSell.guarantor_address
    let sell_guarantor_name = carSell.sell_guarantor_name
    let sell_guarantor_address = carSell.sell_guarantor_address

    this.setState({
      carSell: carSell,
      contract_number: contract_number,
      guarantor_name: sell_guarantor_name === '' ? guarantor_name: sell_guarantor_name,
      guarantor_address: sell_guarantor_address === '' ? guarantor_address : sell_guarantor_address
    })
    this.loadDataCarSellPay(car_id, customer_id)
  }

  loadDataCarSellPay (car_id, customer_id) {
    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' + car_id + '/' + customer_id)
      .then(res => {
        // car_sell_list_pay_id = 1 เงินจอง
        const car_sell_pay_list = res.data
        // console.log(car_sell_pay_list)
        // eslint-disable-next-line
        const pay_car_book = car_sell_pay_list.filter(car_sell_pay => car_sell_pay.car_sell_list_pay_id === 1)

        let sum_car_book = 0
        if (pay_car_book.length > 0) {
          sum_car_book = pay_car_book.reduce((acc, sell_pay) => acc + sell_pay.car_sell_pay_count, 0)
        }

        // console.log('sum_car_book = เงินจอง =' ,sum_car_book)

        this.setState({
          sum_car_book: sum_car_book,
          carSell: {
            ...this.state.carSell,
            sum_car_book: sum_car_book
          }
        })
      }).catch(error => {
        console.log(error)
    })

  }

  onSubmit(e) {
    e.preventDefault()
    const {
      contract_number,
      sum_car_book,
      guarantor_name,
      guarantor_address
    } = this.state
    let carSell = this.props.carSell
    // save contract_number
    // check contract_number props != state save new value
    // send ค่ากลับไปพิมพ์ หน้า SalesList
    if (contract_number === '') {
      AlertError('กรุณากรอกเลขที่สัญญา');
      return
    }

    // eslint-disable-next-line
    if (sum_car_book == '-') {
      AlertError('กำลังคำนวณเงินจอง');
      return
    }

    if (contract_number === carSell.contract_number &&
      guarantor_name === carSell.guarantor_name &&
      guarantor_address === carSell.guarantor_address
    ) {
      // not change contract_number
      this.props.updateContractCarSales(this.state.carSell, false)
    } else {
      // update new contract_number to
      let car_sell_id = carSell.car_sell_id
      let customer_id = carSell.customer_id
      // let sell_guarantor_name = carSell.sell_guarantor_name
      // let sell_guarantor_address = carSell.sell_guarantor_address
      this.setState({
        carSell: {
          ...this.state.carSell,
          contract_number: contract_number,
          guarantor_name: guarantor_name,
          guarantor_address: guarantor_address,
          sell_guarantor_name: guarantor_name,
          sell_guarantor_address: guarantor_address,
        }
      }, () => {
        // TODO: call api update contract_number
        let data = {
          car_sell_id: car_sell_id,
          contract_number: contract_number,
          customer_id: customer_id,
          guarantor_name: guarantor_name,
          guarantor_address: guarantor_address
        }
        axios.put(APIURL + '/car_sell/contract', data).then(res => {
          if (res.data.result === 'success') {
            // AlertSuccess('')
            // update to carSell to SalesList.carSell
            this.props.updateContractCarSales(this.state.carSell, true)
          } else {
            AlertError('ไม่สามารถบันทึกข้อมูลสัญญาได้')
          }
        })
      })
    }

  }

  onInputChange(e){
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }

  render () {
    return <>
      <div className="card card-accent-primary">
        <form action="" method="post"  onSubmit={this.onSubmit}>

          <div className="card-header">
            <strong className="text-title">ข้อมูลสัญญา</strong>
          </div>

          <div className="card-block">
            <div className="row">

              <div className="col-lg-12">
                <div className=" form-group row" style={styles.bottomLine}>
                  <label className="col-md-2"><strong> ชื่อรถ </strong></label>
                  <div className="col-md-10">
                    <p className="text-justify text-success">
                      <strong>
                         {this.props.carSell.car_name}
                         &nbsp;&nbsp;
                         ทะเบียน: {this.props.carSell.car_license_plate_new}
                      </strong>
                    </p>
                  </div>
                </div>

                <div className=" form-group row" style={styles.bottomLine}>
                  <label className="col-md-2"><strong> ชื่อลูกค้า </strong></label>
                  <div className="col-md-10">
                    <p className="text-justify text-success">
                      <strong>
                        {this.props.carSell.customer_name}
                        &nbsp;&nbsp;
                        {this.props.carSell.customer_lastname} &nbsp;&nbsp;({this.props.carSell.customer_mobile})
                      </strong>
                    </p>
                  </div>
                </div>

              </div>

              <div className="form-group col-12">
                <label htmlFor="contract_number">เลขที่สัญญา</label>
                <input type="text"
                       className="form-control"
                       placeholder=""
                       name="contract_number"
                       value={this.state.contract_number}
                       onChange={this.onInputChange}
                />
              </div>

              <div className="form-group col-12">
                <label htmlFor="guarantor_name">ชื่อผู้ค้ำประกัน</label>
                <input type="text"
                       className="form-control"
                       name="guarantor_name"
                       placeholder=""
                       value={this.state.guarantor_name}
                       onChange={this.onInputChange}
                />
              </div>

              <div className="form-group col-12">
                <label htmlFor="guarantor_address">ที่อยู่ผู้ค้ำประกัน</label>
                <input type="text"
                       className="form-control"
                       name="guarantor_address"
                       placeholder=""
                       value={this.state.guarantor_address}
                       onChange={this.onInputChange}
                />
              </div>

            </div>
          </div>

          <div className="col-md-12 text-center">
            <div className="card-block">
              <button type="reset"
                      className="btn btn-md btn-danger mr-2"
                      onClick={this.props.onToggle}
                      style={{width: 120}}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="submit"
                      className="btn btn-success btn-md"
                      style={{width: 120}}
              >
                <i className="icon-check"/>  พิมพ์
              </button>
            </div>
          </div>

        </form>
      </div>
    </>
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  }
}
